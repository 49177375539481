@media only screen and (max-width: 1303px){
    .cd{
      
       display:none;
    }
  }  
  
  @media only screen and (min-width: 1068){
      .form-horizontal{
          margin-top: 200px;
      }
      
  }  
  
  @media only screen and (min-width: 1042px){
    .da{
      display: none;
    }
  }  