@import "../../../assets/scss/theme.scss";

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

.felx-heading{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: right;
  position: relative;
  top:-8.5%;
	align-items: stretch;
	align-content: stretch;

  @media (max-width: 650px) {
   top:0
  }
}

.line_graph_date_picker{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  justify-content:flex-end;
  margin-top:-5px;
  margin-bottom:20px;
}