//
//

.disappearing-button-container-page-top-right {
    position: absolute;
    right: 340px;
    top: 85px;
    text-align: 'center';
    z-index: 10;
    
    @media (max-width: 850px) {
      display: none;
    }
  }
  
  .appearing-button-container-page-bottom-right {
    position: absolute;
    bottom: 80px;
    right: 50px;
    text-align: 'center';
    z-index: 10;
    @media (min-width: 850px) {
      display: none;
    }
  }
  .invalid{
    border-color:1px solid red;
  }
  .details_card{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      margin-top: -10px;
      margin-left: 2rem;
  }
  .details_card_child {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      padding: 0px 45px 0px 50px;
      
    }
  
    .details_card__Togglebutton{
      display: flex;
      justify-content: flex-end;
      
    }

.detail__card__rightLogos{
  width: 15%;
  height: 200px;
  min-width: 200px;
}


    .detail_item {
      width: 100%;
      height: 550px;
      
     
      background-color: #fff;
      transition: height 500ms ease;
      overflow: hidden;
  }
  
  .detail_none {
      height: 180px;
      margin-bottom: 20px;
  
      
  }
  
  .detail_showing {
      height: 560px;
      display: block !important;
  }
  
  .gtcc__detail_showing{
    height: 420px;
    display: block !important;
  }

  .card__tiles{
  
  border-radius: 0.6em;
  box-shadow: 3px 3px 11px 0px rgba(196,196,196,1);
  transition: all ease 200ms;

   
  }

  .card__tiles:hover {
    transform: scale(1.02);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  }

  .card__tiles__image{
    border-radius: 0.6em;
    cursor: pointer;
    transition: all ease 200ms; 
    }
  
    .card__tiles__image:hover {
      transform: scale(1.04);
    }

 
  .card__tile__imagegrid{
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr;
    grid-column-gap: 10px;

    @media (max-width: 850px) {
      display: flex;
      flex-wrap: wrap;
    }

  }

  .timing__Heading{
    display:flex;
    justify-content:space-evenly;
    align-content:center;
    margin-left:30px;
    margin-top:40px;

  }