.schedule-items-container{
    display:flex;
    flex-direction: row;
    width: 100%;
    min-width: 170px;
    justify-content: center;
   
}

.schedule-item-main{
    
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 170px;
    transition: all ease 200ms;

   
}
.schedule-item{
    width: 100%;
    border-radius: 0.1em;
    box-shadow: 3px 3px 11px 0px rgba(196,196,196,1);
    -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* width: 170px; */
    /* min-width: 170px; */
    height: 40px;
    cursor: pointer;
    transition: all ease 200ms;
}

.schedule-item:hover{
   
    transform: scale(1.04);
}

.schedule-titles{
    font-family: "Times New Roman", Times, serif;
    
}

.schedule-item-tables{
    width: 100%;
    /* min-width: 170px; */
    /* width: 170px; */
    margin-top: 8px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

  
    
}

/* .schedule_item_container{
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
    gap: 0px 0px; 
     grid-template-areas: 
    ". . . . . . ."
    ". . . . . . ."
    ". . . . . . ."; 
} */